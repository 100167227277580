import React from "react";
import Project from "./Project/Project.js";
import ScrabbleImage from "./Project/GitHubImages/ScrabbleImage.png";
import AuctionHouse from "./Project/GitHubImages/AuctionHouse.png";
import FinalWish from "./Project/GitHubImages/FinalWish.png";
import KenziesCopy from "./Project/GitHubImages/kenziescopy.png";
import { Stack } from "@mui/material";

function Projects() {
    return (
        <Stack mt={2} direction="row" justifyContent="center" spacing={4}>
            <Project
                title="FinalWish"
                image={FinalWish}
                link="https://github.com/willdebernardi/XIVSite"
                desc="A wishlist style website using the MERN stack to allow for user authenticationa and a personalized item wishlist for the popular MMO Final Fantasy XIV."
            />
            <Project
                title="Kenzie's Copy"
                image={KenziesCopy}
                link="https://www.kenziescopy.com/"
                desc="Professional portfolio website for a freelance copywriter with an emphasis in attracting new clientele."
            />
            <Project
                title="Scrabble GUI"
                image={ScrabbleImage}
                link="https://github.com/jonsalaz/JavaFX-Scrabble"
                desc="Demonstration of algorithmic solver to determine the best move possible of any given hand in a game of Scrabble."
            />
            <Project
                title="Auction House"
                image={AuctionHouse}
                link="https://github.com/jonsalaz/Auction-House"
                desc="Terminal Based Auction House using Java Sockets to communicate between the bank, the Auction House, and an Agent."
            />
        </Stack>
    );
}

export default Projects;
